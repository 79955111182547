import { useSelector } from 'react-redux';
import {
  ConfigItemWrapper,
  Container,
  Label,
  NoConfig,
  Title,
  Value,
} from './TestConfigurationPreview.styled';
import { ITestCompanyModelConfiguration } from 'types/Configuration.types';
import { IRootReducerState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ITestDealerPanelConfigurationNavigationState } from 'pages/DealerPanel/DealerPanelModelsPage/SelectVATAmountModal/SelectVATAmountModal';

interface IConfigItemProps {
  label: string;
  value: string;
  isLast?: boolean;
}

const ConfigItem = ({ label, value, isLast }: IConfigItemProps) => {
  const { t } = useTranslation();
  return (
    <ConfigItemWrapper isLast={isLast}>
      <Label>{t(label)}:</Label>
      <Value>{['true', 'false'].includes(value) ? t(value) : value}</Value>
    </ConfigItemWrapper>
  );
};

export const TestConfigurationPreview = () => {
  const testConfiguration: ITestCompanyModelConfiguration | null = useSelector(
    (state: IRootReducerState) =>
      state.submitConfigurationInfo.testCompanyModelConfiguration
  );
  const { t } = useTranslation();
  const location = useLocation();
  const { state }: { state: ITestDealerPanelConfigurationNavigationState } =
    useLocation();

  const queryParams = new URLSearchParams(location.search);
  const taxRateParam = queryParams.get('taxRate');

  if (!testConfiguration) {
    return <NoConfig>{t('No test configuration available')}</NoConfig>;
  }

  return (
    <Container>
      <Title>{t('Test Configuration Preview')}</Title>
      <ConfigItem
        label="VAT percentage"
        value={taxRateParam?.toString() || ''}
      />
      <ConfigItem
        label="Model ID"
        value={testConfiguration.model_id.toString()}
      />
      <ConfigItem
        label="Base markup"
        value={`${testConfiguration.base_markup}%`}
      />
      <ConfigItem
        label="Base purchase discount"
        value={`${testConfiguration.base_purchase_discount}%`}
      />
      <ConfigItem
        label="Surcharge markup"
        value={`${testConfiguration.surcharge_markup}%`}
      />
      <ConfigItem
        label="Surcharge purchase discount"
        value={`${testConfiguration.surcharge_purchase_discount}%`}
      />
      <ConfigItem
        label="Fixed amount"
        value={`$${testConfiguration.fixed_amount.toFixed(2)}`}
      />
      <ConfigItem
        label="Exclude surcharge amount"
        value={testConfiguration.exclude_surcharge.toString()}
      />
      <ConfigItem
        label="Round prices"
        value={testConfiguration.round_prices.toString()}
        isLast={!state?.supplierHasSystemIntegration}
      />
      {state?.supplierHasSystemIntegration ? (
        <ConfigItem
          label="Supplier discount"
          value={testConfiguration.is_using_supplier_system_discounts.toString()}
          isLast
        />
      ) : null}
    </Container>
  );
};
