import { EditConfigurationValidationWarningsModal } from 'components/Modals/EditConfigurationValidationWarningsModal/EditConfigurationValidationWarningsModal';
import ConfigureForm from './ConfigureForm/ConfigureForm';
import {
  AbsoluteBackWrapper,
  BackLabel,
  ByCodeflexText,
  ByCodeflexWrapper,
  Container,
  NoModelSelectedLabel,
  NoModelSelectedWrapper,
  Row,
  Wrapper,
} from './ConfigurePage.styled';
import FormImages from './FormImages/FormImages';
import { ReactComponent as CodeflexLogo } from 'assets/icons/codeflexLogoTextRight.svg';
import { useTranslation } from 'react-i18next';
import { getModelIdFromPath } from 'providers/QueryProvider/hooks';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IImageSection, useFilterOutHiddenImages } from './FormImages/hooks';
import { useHasImages } from './hooks';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { RoutesConfig } from 'navigation/routes';
import { AppMode } from 'store/Common/reducers/common';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { CaretLeft } from '@phosphor-icons/react';

const ConfigurePage = () => {
  const { t } = useTranslation();
  const modelId = getModelIdFromPath();
  const navigate = useNavigate();
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  const images = useSelector(
    (state: IRootReducerState) => state.modelInfo.images
  );
  const visibleImages: IImageSection[] = useFilterOutHiddenImages(images);

  // Used for toggling between 2 form styles
  const hasImages = useHasImages(!!visibleImages.length);

  const general = useSelector(
    (state: IRootReducerState) => state.modelInfo.general
  );
  usePageTitle(general ? general.name : 'Configure', general ? false : true);

  return (
    <>
      {!modelId ? (
        <NoModelSelectedWrapper>
          <NoModelSelectedLabel>
            {t('No model is selected')}
          </NoModelSelectedLabel>
          {appMode === AppMode.COMPANY ? (
            <Button
              label={t('Back to models page')}
              onClick={() => navigate(RoutesConfig.Models.fullPath)}
              primary
            />
          ) : null}
        </NoModelSelectedWrapper>
      ) : (
        <Wrapper>
          <Container hasImages={hasImages}>
            {appMode === AppMode.COMPANY ? (
              <AbsoluteBackWrapper
                onClick={() => navigate(RoutesConfig.Models.fullPath)}
              >
                <Icon
                  svg={CaretLeft}
                  color={COLORS.GREEN}
                  size={20}
                  weight="bold"
                  onClick={() => null}
                />
                <BackLabel>{t('Back')}</BackLabel>
              </AbsoluteBackWrapper>
            ) : null}
            <Row>
              <FormImages visibleImages={visibleImages} />
              <ConfigureForm hasImages={hasImages} />
            </Row>
            <ByCodeflexWrapper>
              <ByCodeflexText>{t('Powered by')}:</ByCodeflexText>
              <Icon svg={CodeflexLogo} size={123} fill={COLORS.BLACK} />
            </ByCodeflexWrapper>
            <EditConfigurationValidationWarningsModal />
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export default ConfigurePage;
