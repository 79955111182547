import { COLORS, borderRadiusSm } from 'assets/styled';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  isLast: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
`;

export const Title = styled.label`
  font-weight: bold;
  margin-bottom: 1rem;
  grid-column: span 2;
  text-align: center;
  padding: 5rem;
  color: ${COLORS.BLACK};
`;

export const ConfigItemWrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  gap: 4rem;
  ${(props: StyledProps) =>
    !props.isLast &&
    css`
      border-bottom: 1px solid ${COLORS.BORDER};
    `};
`;

export const Label = styled.span`
  font-weight: 600;
  color: ${COLORS.BLACK};
`;

export const Value = styled.span`
  color: ${COLORS.BLACK};
`;

export const NoConfig = styled.div`
  text-align: center;
  padding: 4rem;
  background-color: ${COLORS.WHITE};
`;
