import { COLORS, borderRadiusSm, gapXs, paddingSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

interface StyledProps {
  areBothPriceTypesShown: boolean;
}

export const ContainerAbsolute = styled.div`
  max-height: 80vh;
  overflow: auto;
  position: absolute;
  border-radius: ${borderRadiusSm};
  background-color: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.BORDER};
  min-width: 200rem;
  top: 50rem;
  padding: ${paddingSm};
  display: flex;
  flex-direction: column;
  gap: ${gapXs};

  ${respondTo.smallTablet`
    min-width: auto;
    position: fixed;
    top: auto;
    bottom: 130rem;
    left: 0rem;
    right: 0rem;
  `}
`;

export const PriceLabel = styled.div`
  min-width: 100rem;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    min-width: 90rem;
  `}
`;

export const PriceLabelValue = styled.div`
  min-width: 50rem;
  color: ${COLORS.BLACK};
`;

export const TooltipPriceLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${gapXs};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props: StyledProps) =>
    props.areBothPriceTypesShown ? '1fr 1fr 1fr' : '1fr 1fr'};
  gap: 16px;

  ${respondTo.smallTablet`
    gap: 5px;
  `}
`;

export const Margin34 = styled.div`
  width: 34rem;
  height: 24rem;
`;
