import {
  Container,
  Label,
  LeftPart,
  CompanyNameLabel,
  RightPart,
  CompanyTooltipContentWrapper,
  CompanyTooltipRow,
  CompanyTooltipLabel,
  CompanyTooltipLabelValue,
} from './SupplierCompanyRow.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import { useNavigate } from 'react-router-dom';
import { ICompany } from 'types/Company.types';
import { RoutesConfig } from 'navigation/routes';
import { ISupplierCompany } from 'types/SupplierCompany';
import { Info, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface ISupplierCompanyRowProps {
  company: ICompany;
  withoutLine: boolean;
  onDelete: (supplierCompanyId: number) => void;
}

const renderCompanyTooltipContent = (
  company: ICompany,
  t: (str: string) => string
) => {
  return (
    <CompanyTooltipContentWrapper>
      <CompanyTooltipRow>
        <CompanyTooltipLabel>{t('Phone number')}:</CompanyTooltipLabel>
        <CompanyTooltipLabelValue>
          {company.phone_number}
        </CompanyTooltipLabelValue>
      </CompanyTooltipRow>
      <CompanyTooltipRow>
        <CompanyTooltipLabel>{t('E-mail')}:</CompanyTooltipLabel>
        <CompanyTooltipLabelValue>{company.email}</CompanyTooltipLabelValue>
      </CompanyTooltipRow>
      <CompanyTooltipRow>
        <CompanyTooltipLabel>{t('Contact person')}:</CompanyTooltipLabel>
        <CompanyTooltipLabelValue>
          {company.contact_person}
        </CompanyTooltipLabelValue>
      </CompanyTooltipRow>
      <CompanyTooltipRow>
        <CompanyTooltipLabel>{t('VAT number')}:</CompanyTooltipLabel>
        <CompanyTooltipLabelValue>
          {company.vat_number}
        </CompanyTooltipLabelValue>
      </CompanyTooltipRow>
      <CompanyTooltipRow>
        <CompanyTooltipLabel>{t('COC number')}:</CompanyTooltipLabel>
        <CompanyTooltipLabelValue>
          {company.coc_number}
        </CompanyTooltipLabelValue>
      </CompanyTooltipRow>
      <CompanyTooltipRow>
        <CompanyTooltipLabel>{t('Website')}:</CompanyTooltipLabel>
        <CompanyTooltipLabelValue>{company.website}</CompanyTooltipLabelValue>
      </CompanyTooltipRow>
    </CompanyTooltipContentWrapper>
  );
};

const SupplierCompanyRow = ({
  company,
  withoutLine,
  onDelete,
}: ISupplierCompanyRowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const supplierCompany: ISupplierCompany | undefined =
    company.supplier_companies?.[0];

  return (
    <Container withoutLine={withoutLine}>
      <LeftPart>
        <CompanyNameLabel>{company.name}</CompanyNameLabel>
        <Tooltip content={renderCompanyTooltipContent(company, t)}>
          <Icon svg={Info} size={20} color={COLORS.GREEN} />
        </Tooltip>
      </LeftPart>
      <RightPart>
        <Label>
          {t('Reference number')}: {supplierCompany?.supplier_company_reference}
        </Label>
        <Button
          label={t('Open')}
          primary
          width="100rem"
          height="25rem"
          onClick={() =>
            navigate(
              RoutesConfig.SupplierSingleCompany.fullPath.replace(
                ':id',
                String(company.id)
              ),
              { state: company }
            )
          }
        />
        <Icon
          onClick={() =>
            supplierCompany && onDelete(Number(supplierCompany?.id))
          }
          svg={Trash}
          color={COLORS.RED_100}
        />
      </RightPart>
    </Container>
  );
};

export default SupplierCompanyRow;
