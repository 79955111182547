import {
  SubHeaderContainer,
  PricingContainer,
  TotalPrice,
  SubHeaderContentWrapper,
  ModelNameLabel,
  AbsolutePricingWrapper,
  LeftSide,
  InfoIconWrapper,
  StyledIcon,
} from './SubHeader.styled';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { AddedToCartModal } from 'components/Modals/AddedToCartModal/AddedToCartModal';
import { useEffect, useRef, useState } from 'react';
import {
  setCurrentlyEditingShoppingCartLineId,
  triggerAddToShoppingCart,
  triggerEditCartItem,
} from 'store/ShoppingCart/actions/shoppingCart';
import { triggerSubmitConfiguration } from 'store/SubmitConfiguration/actions/submitConfiguration';
import { RoutesConfig } from 'navigation/routes';
import { CartItemEditFinishedModal } from 'components/Modals/CartItemEditFinishedModal/CartItemEditFinishedModal';
import {
  resetTouchedQuestionNames,
  setAreAllFieldsTouched,
} from 'store/Form/actions/form';
import {
  SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL,
  SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
} from 'store/constants';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import {
  useCalculateAndFormatPrices,
  useIsEditOrAddToShoppingCartRequestInProgress,
  useShouldRoundNumbers,
} from './hooks';
import { useTranslation } from 'react-i18next';
import PricingSection from './PricingSection/PricingSection';
import { useShouldShowSpecificPrices } from './PricingSection/hooks';
import Tooltip from 'components/Tooltip/Tooltip';
import { getTooltipText } from 'pages/ConfigurePage/ConfigureForm/FormItem/helpers';
import { resetConfigureForm } from 'store/Model/actions/model';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { COLORS } from 'assets/styled';
import { Skeleton } from '@mui/material';
import Icon from 'components/Icon/Icon';
import { AppMode } from 'store/Common/reducers/common';
import Button from 'components/Button/Button';
import { shouldShowRedBorder } from './helpers';
import { CaretDown, Info } from '@phosphor-icons/react';
import { TestConfigurationPreview } from './TestConfigurationPreview/TestConfigurationPreview';

interface ISubHeaderProps {
  isExpanded: boolean;
}

const SubHeader = ({ isExpanded }: ISubHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isPricingSectionVisible, setIsPricingSectionVisible] =
    useState<boolean>(false);
  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);
  const { isFormValid, areAllFieldsTouched } = useSelector(
    (state: IRootReducerState) => state.formInfo
  );
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  const {
    triggerShowAddedToCartModal,
    triggerShowCartItemEditedModal,
    currentlyEditingShoppingCartLineId,
  } = useSelector((state: IRootReducerState) => state.shoppingCartInfo);
  const [isAddedToCartModalOpen, setIsAddedToCartModalOpen] = useState(false);
  const [isCartItemEditFinishedModalOpen, setIsCartItemEditFinishedModalOpen] =
    useState(false);

  const navigateWithQuery = useNavigateWithQuery();

  useEffect(() => {
    if (triggerShowAddedToCartModal) {
      setIsAddedToCartModalOpen(true);
      dispatch(resetConfigureForm());
      dispatch(resetTouchedQuestionNames());
      dispatch(setAreAllFieldsTouched(false));
      dispatch({ type: SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL, payload: false });
    }
  }, [triggerShowAddedToCartModal]);
  useEffect(() => {
    if (triggerShowCartItemEditedModal) {
      setIsCartItemEditFinishedModalOpen(true);
      dispatch(resetConfigureForm());
      dispatch(resetTouchedQuestionNames());
      dispatch(setAreAllFieldsTouched(false));
      dispatch(setCurrentlyEditingShoppingCartLineId(null));
      dispatch({
        type: SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
        payload: false,
      });
    }
  }, [triggerShowCartItemEditedModal]);

  const handleAddToCart = () => {
    dispatch(triggerAddToShoppingCart(true)); // Handled in useListenForAddToCartTrigger
  };

  const handleEditCartItem = () => {
    dispatch(triggerEditCartItem(true)); // Handled in useListenForEditCartItemTrigger
    navigateWithQuery(RoutesConfig.Configure.fullPath); // QueryProvider has useListenForAddToCartTrigger, but it triggers only on ConfigurePage
  };

  // Blueprint integration
  const handleSubmitConfiguration = () => {
    dispatch(triggerSubmitConfiguration(true)); // Handled in useListenForSubmitConfigurationTrigger
    navigateWithQuery(RoutesConfig.Configure.fullPath);
  };

  const queryRequestStatus = useSelector(
    (state: IRootReducerState) => state.modelInfo.queryStatus
  );

  const isTestMode = appMode === AppMode.DEALER_PANEL;
  const getButtonLabel = () => {
    if (appMode === AppMode.DEALER_PANEL) {
      return t('Test mode');
    }
    if (appMode === AppMode.BLUEPRINT) {
      return t('Submit');
    } else {
      if (currentlyEditingShoppingCartLineId) {
        return t('Edit cart item');
      } else {
        return t('Add to cart');
      }
    }
  };

  const pricingContainerRef = useRef(null);
  useOutsideAlerter(pricingContainerRef, () =>
    setIsPricingSectionVisible(false)
  );

  const [
    totalBaseNetPrice,
    totalBaseGrossPrice,
    totalNetPrice,
    totalGrossPrice,
    formattedPrices,
    isPricingValid,
  ] = useCalculateAndFormatPrices(modelInfo?.pricing);
  const isPricingEmpty = totalBaseNetPrice === 0 && totalBaseGrossPrice === 0;

  const { showNet, showGross, isUsingSupplierSystemDiscounts } =
    useShouldShowSpecificPrices();
  const shouldRoundNumbers = useShouldRoundNumbers();

  const isPriceUnknown = showNet
    ? totalNetPrice === null
    : totalGrossPrice === null;

  const pricelist = modelInfo?.general?.pricelist;
  const pricelistVersion = modelInfo?.general?.pricelist_version;

  const isEditOrAddToShoppingCartRequestInProgress =
    useIsEditOrAddToShoppingCartRequestInProgress();

  return (
    <SubHeaderContainer isExpanded={isExpanded}>
      <SubHeaderContentWrapper isExpanded={isExpanded}>
        <LeftSide>
          <ModelNameLabel>
            {modelInfo?.general?.name ? (
              modelInfo.general.name
            ) : !queryRequestStatus.attempt ? (
              <Skeleton width={'300rem'} height={'40rem'} animation="wave" />
            ) : (
              t('Unknown model')
            )}
          </ModelNameLabel>
          {pricelist || pricelistVersion ? (
            <Tooltip
              content={getTooltipText(
                ['Pricelist', 'Pricelist Version'],
                [pricelist, pricelistVersion],
                t
              )}
            >
              <InfoIconWrapper>
                <Icon svg={Info} size={20} color={COLORS.GREEN} />
              </InfoIconWrapper>
            </Tooltip>
          ) : null}
        </LeftSide>
        <AbsolutePricingWrapper>
          {isPriceUnknown ? (
            <TotalPrice>{t('Unknown price')}</TotalPrice>
          ) : (
            <PricingContainer ref={pricingContainerRef}>
              <TotalPrice>
                {showNet
                  ? '€' + totalNetPrice?.toFixed(shouldRoundNumbers ? 0 : 2)
                  : '€' + totalGrossPrice?.toFixed(shouldRoundNumbers ? 0 : 2)}
              </TotalPrice>
              {isPricingEmpty ? null : (
                <StyledIcon
                  svg={CaretDown}
                  size={20}
                  color={COLORS.BLACK}
                  onClick={() =>
                    setIsPricingSectionVisible(!isPricingSectionVisible)
                  }
                  isPricingSectionVisible={isPricingSectionVisible}
                />
              )}
              {isPricingSectionVisible && (
                <PricingSection formattedPrices={formattedPrices} />
              )}
              {isPricingValid ? null : t('Invalid dimensions')}
            </PricingContainer>
          )}
        </AbsolutePricingWrapper>
        {isExpanded && appMode !== AppMode.SUPPLIER ? (
          <Tooltip
            content={
              appMode == AppMode.DEALER_PANEL ? (
                <TestConfigurationPreview />
              ) : null
            }
          >
            <Button
              buttonStyle={
                shouldShowRedBorder(
                  isTestMode,
                  areAllFieldsTouched,
                  queryRequestStatus?.attempt,
                  isFormValid
                )
                  ? { border: `1rem solid ${COLORS.RED}` }
                  : {}
              }
              pwId={'submit-form-button'}
              disabled={
                isEditOrAddToShoppingCartRequestInProgress ||
                isTestMode ||
                (areAllFieldsTouched &&
                  (queryRequestStatus.attempt ||
                    !isFormValid ||
                    !isPricingValid))
              }
              primary
              width="150rem"
              label={getButtonLabel()}
              onClick={() => {
                if (isFormValid && isPricingValid) {
                  if (appMode === AppMode.BLUEPRINT) {
                    handleSubmitConfiguration();
                  } else {
                    if (currentlyEditingShoppingCartLineId) {
                      handleEditCartItem();
                    } else {
                      handleAddToCart();
                    }
                  }
                } else if (!areAllFieldsTouched) {
                  dispatch(setAreAllFieldsTouched(true));
                }
              }}
            />
          </Tooltip>
        ) : null}
      </SubHeaderContentWrapper>
      <AddedToCartModal
        isOpen={isAddedToCartModalOpen}
        setIsOpen={setIsAddedToCartModalOpen}
      />
      <CartItemEditFinishedModal
        isOpen={isCartItemEditFinishedModalOpen}
        setIsOpen={setIsCartItemEditFinishedModalOpen}
      />
    </SubHeaderContainer>
  );
};

export default SubHeader;
