import styled, { css } from 'styled-components';
import { COLORS, paddingLg } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isExpanded: boolean;
  isPricingSectionVisible: boolean;
  showRedBorder: boolean;
}

export const SubHeaderContainer = styled.div`
  width: 100%;
  padding: 0 ${paddingLg};
  border-top: 1rem solid ${COLORS.BORDER};
  background-color: ${COLORS.WHITE};
  transition: height ease 200ms;
  ${(props: StyledProps) =>
    props.isExpanded
      ? css`
          height: 75rem;

          ${respondTo.smallTablet`
            height: 100rem;
            padding: 10rem 20rem;
          `}
        `
      : css`
          height: 0;
          border-top: 0;
        `}

  ${respondTo.smallTablet`
    position: fixed;
    bottom: 0;
  `}
`;

export const SubHeaderContentWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: opacity ease 100ms;
  ${(props: StyledProps) =>
    props.isExpanded
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}

  ${respondTo.smallTablet`
    flex-wrap: wrap;
    justify-content: center;
  `}
`;

export const PricingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20rem;
  flex-direction: row;
  margin-right: 45rem;

  ${respondTo.smallTablet`
    margin-right: 0;
    margin-left: auto;
  `}
`;
export const TotalPrice = styled.div`
  font-size: 30rem;
  font-weight: 600;
  color: ${COLORS.GREEN};
  line-height: 40.85rem;

  ${respondTo.smallLaptop`
    font-size: 26rem;
  `}

  ${respondTo.bigTablet`
    font-size: 24rem;
  `}

   ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const ModelNameLabel = styled.label`
  font-size: 25rem;
  line-height: 30rem;
  font-weight: 400;
  min-width: 30%;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 22rem;
  `}

  ${respondTo.bigTablet`
    font-size: 20rem;
  `}

  ${respondTo.smallTablet`
    font-size: 18rem;
    min-width: auto;
  `}
`;

export const AbsolutePricingWrapper = styled.div`
  position: absolute;
  left: 48%;

  ${respondTo.smallTablet`
    left: auto;
    position: static;
    width: 50%;
  `}
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;

  ${respondTo.smallTablet`
    width: 100%;
    justify-content: center;
  `}
`;

export const InfoIconWrapper = styled.div`
  height: 40rem;
`;

export const StyledIcon = styled(Icon)`
  ${respondTo.smallTablet`
    transform: rotate(180deg);
  `}

  ${(props: StyledProps) =>
    props.isPricingSectionVisible &&
    css`
      transform: rotate(180deg);

      ${respondTo.smallTablet`
        transform: rotate(0);
      `}
    `};
`;
